<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-container class="py-0">
            <v-form ref="form" v-model="valid1" lazy-validation id="createForm">
              <v-row wrap>
                <v-col cols="12" md="12">
                  <h5><span class="text-danger">* </span>Kindly Note :</h5>
                  <ol class="font-size-h6">
                    <li>
                      Please download sample file here.
                      <a :href="SampleFilePath" target="_blank"
                        >Download sample file</a
                      >
                    </li>
                  </ol>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col cols="12" md="3">
                  <h6><span class="text-danger">*</span> Zone</h6>
                  <v-autocomplete
                    @change="getLomCodeOptions"
                    :reduce="(option) => option.value"
                    :loading="ZoneCodeOptionsLoading"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <h6><span class="text-danger">*</span> LOM</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :rules="LomCodeRules"
                    :loading="LomCodeOptionsLoading"
                    :items="LomCodeOptions"
                    v-model="LomCode"
                    dense
                    clearable
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <h6 class="mycard-text1 fsize-3">
                    <span class="text-danger">* </span>Upload Excel File
                  </h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    placeholder="Upload Excel File"
                    v-model="UploadedFile"
                    prepend-icon="mdi-file-excel"
                  >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="resetUploadFile">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container class="py-0" v-if="ErrorMessageTxtFlag == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0">
            <v-row wrap v-if="SuccessMessageTxtFlag == 1">
              <v-col align="center" cols="12" md="12">
                <h3 class="green--text">{{ SuccessMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>
          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessage: "",
      ErrorMessage: "",

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      SubmitFlag: false,
      valid1: true,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 12,
      JciYearCodeOptions: [],

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "LOM is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      ResultFlag: 0,

      SampleFilePath: "",

      UploadedFile: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath =
          server_url + "assets/sample/lom_manual_members_upload_sample.xlsx";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;
      }

      this.getZoneCodeOptions();
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
  },
  created() {},
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    resetUploadFile() {
      console.log("resetFile called");
      this.UploadedFile = {};
      this.ErrorMessage = "";
      this.ErrorMessageTxt = {};
      this.SuccessMessage = "";
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_member_upload",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },

    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
    },
    submitForm() {
      console.log("submitForm is called");
      this.resetMessageTxt();
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.previewRecords();
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "Kindy fill required fields and then submit";
        console.log("some errors occured");
      }
    },
    previewRecords() {
      console.log("previewRecords called");

      this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      console.log("server_url=" + server_url + ", token=" + token);

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (result) {
        var recordObj = {
          UI: 1,
        };
        console.log("recordObj=" + JSON.stringify(recordObj));

        let upload = new FormData();
        upload.append("token", token);
        upload.append("chapter_id", this.LomCode);
        upload.append("uploaded_file", this.UploadedFile);
        upload.append("form", JSON.stringify(recordObj));

        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        var records = [];

        thisIns.ProgessStart = 1;
        thisIns.valid = false;

        var CancelToken = this.CancelToken;
        var cancel;

        var add_url = server_url + "api/members/bulk";

        console.log("add_url=" + add_url);

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
          .then(function (response) {
            thisIns.ProgessStart = 0;
            thisIns.valid = true;

            console.log("response=" + JSON.stringify(response));
            var status = response.status;
            console.log("status=" + status);
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log(
              "output=" +
                output +
                ", flag=" +
                flag +
                ", records=" +
                JSON.stringify(records)
            );
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.records = records;

            if (flag == 1) {
              thisIns.SuccessMessageTxtFlag = 1;
              thisIns.SuccessMessageTxt = successTxt;
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = errorTxt;
            }
          })
          .catch(function (error) {
            thisIns.ProgessStart = 0;
            thisIns.valid = true;

            console.log("error=" + error);
            var errCode = error.response.status;
            thisIns.ErrorMessageTxtFlag = 1;
            errorTxt = "";
            if (thisIns.$http.isCancel(error)) {
              errorTxt +=
                "This is taking longer than usual. Kindly contact web administrator. ";
            } else {
              errorTxt +=
                "This is taking longer than usual. Kindly contact web administrator.. ";
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.headers);
                errorTxt += " .";
              } else if (error.request) {
                console.log(error.request);
                errorTxt += " ..";
              } else {
                console.log("Error", error);
                errorTxt += " ...";
              }
            }
            thisIns.ErrorMessageTxt = errorTxt;
          });
      } else {
        var errorTxt = "Kindy upload file to import";
        thisIns.ErrorMessageTxtFlag = 1;
        thisIns.ErrorMessageTxt = errorTxt;
      }
    },
    uploadRecords() {
      console.log("uploadRecords called");

      this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      console.log("server_url=" + server_url + ", token=" + token);

      var ValidRecordsFlag = this.ValidRecordsFlag;
      var InvalidRecordsFlag = this.InvalidRecordsFlag;
      console.log(
        "ValidRecordsFlag=" +
          ValidRecordsFlag +
          ", InvalidRecordsFlag=" +
          InvalidRecordsFlag
      );

      if (ValidRecordsFlag == 1 && InvalidRecordsFlag == 0) {
        var recordObj = {
          records: this.records,
        };
        console.log("recordObj=" + JSON.stringify(recordObj));

        var upload = {
          token: token,
          form: recordObj,
        };

        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        var records = [];

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;
        thisIns.valid = false;

        var CancelToken = this.CancelToken;
        var cancel;

        var headers = "application/x-www-form-urlencoded";
        var add_url = server_url + "api/judges/import_submit";

        console.log("add_url=" + add_url);

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": headers,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        })
          .then(function (response) {
            thisIns.ProgessStart = 0;
            // thisIns.pageLoadingOff()
            thisIns.valid = true;

            console.log("response=" + JSON.stringify(response));
            var status = response.status;
            console.log("status=" + status);
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log(
              "output=" +
                output +
                ", flag=" +
                flag +
                ", records=" +
                JSON.stringify(records)
            );
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.reset();

              thisIns.SuccessMessageTxtFlag = 1;
              thisIns.SuccessMessageTxt = successTxt;
              /*
              thisIns.alert = {
                flag: true,
                color: "info",
                message: successTxt,
              };
              */
              // thisIns.$router.push("/judges-list");
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = errorTxt;
              /*
              thisIns.alert = {
                flag: true,
                color: "error",
                message: errorTxt,
              };
              */
            }
          })
          .catch(function (error) {
            thisIns.ProgessStart = 0;
            thisIns.valid = true;

            console.log("error=" + error);
            var errCode = error.response.status;
            thisIns.ErrorMessageTxtFlag = 1;
            errorTxt = "";
            if (thisIns.$http.isCancel(error)) {
              // console.log('im canceled')
              // errorTxt += "Network error. Kindly try again. "
              errorTxt +=
                "This is taking longer than usual. Kindly login with your President's username and password for acknowledgement. ";
            } else {
              errorTxt +=
                "This is taking longer than usual. Kindly login with your President's username and password for acknowledgement.. ";
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.headers);
                errorTxt += " .";
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                errorTxt += " ..";
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error);
                errorTxt += " ...";
              }
            }
            thisIns.ErrorMessageTxt = errorTxt;
          });
      } else {
        var message = "Atleast One details required to add Award";
        this.errorMessage(message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  
  <style lang="scss">
</style>
  